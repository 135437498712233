.header-body {
    display: grid;
    background: linear-gradient(#b7efc5, #eff7f6);
    grid-template-columns: 50% 50%;
    grid-template-rows: 0.05fr 0.75fr 0.05fr 0.05fr;
    grid-template-areas: 
    "name name"
    "bitmoji bitmoji"
    "bank dev"
    "logos logos";
    width: 100%;
}

.bitmoji {
    grid-area: bitmoji;
    height: 70%;
    margin: auto;
    padding-top: 0.5rem;
}

.logos, .logos-mobile {
    grid-area: logos;
    height: 10vh;
    margin-top: 8%;
    overflow: hidden;
}

.name {
    grid-area: name;
    margin: auto;
    padding-top: 1rem;
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 3%;
}

.developer {
    grid-area: dev;
    position: absolute;
    left: 50%;
    font-weight: 600;
    padding-left: 30px;
    margin-top: 31%;
    font-size: 70px;
}

*,
*:before,
*:after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins",sans-serif;
    font-size: 45px;
    font-weight: 500;
}

.wrapper{
    grid-area: bank;
    box-sizing: content-box;
    height: 2rem;
    display: flex;
    position: absolute;
    right: 48%;
    margin-top: 31%;
    padding-right: 20px;
    
}
.words{
    overflow: hidden;
}
.word-bank {
    display: block;
    padding-left: 10px;
    color: #06d6a0;
    animation: spin_words 2.5s infinite;
    font-weight: 600;
    padding-right: 15px;
    font-size: 70px;
    height: 140%;
}

.back {
    color: #248277;
}

.row-1, .row-2 {
    display: table;
    margin: 0 auto;
}

.row-1 td *, .row-2 td *{
    padding-left: 25px;
    font-size: 30px;
    margin: auto;
}

.logos-mobile {
    visibility: hidden;
}

.logos td *{
    height: 65px;
}
@keyframes spin_words{
    15%{
        transform: translateY(-110%);
    }
    45%{
        transform: translateY(-100%);
    }
    75%{
        transform: translateY(-210%);
    }
    100%{
        transform: translateY(-200%);
    }
    
}

@media only screen and (max-width: 1605px) and (min-width: 721px) {
    .wrapper, .developer {
        padding-top: 2rem;
    }
}


@media only screen and (max-width: 720px) and (min-width: 369px) {
    .wrapper, .developer {
        padding-top: 8rem;
    }

    .wrapper {
        padding-right: 0;
    }

    .developer {
        padding-left: 0;
    }

    .logos {
        visibility: hidden;
    }

    .logos-mobile {
        visibility: visible;
        padding-right: 15px;
        padding-top: 2rem;
        height: 25vh;
    }

    .row-2 td *{
        padding-left: 10px;
        padding-right: 10px;
        font-size: 40px;
        display: table;
        margin: 0 auto;
        
    }

    .developer, .word-bank {
        font-size: 40px;
    }

    .bitmoji {
        height: 60%;
        padding-top: 0.25rem;
    }

    .name {
        font-size: 80px;
    }
}

@media only screen and (max-width: 368px) {
    .developer, .word-bank {
        font-size: 40px;
    }

    .wrapper, .developer {
        padding-top: 8rem;
    }

    .wrapper {
        padding-right: 0;
    }

    .developer {
        padding-left: 0;
    }

    .logos {
        visibility: hidden;
    }

    .bitmoji {
        height: 60%;
    }

    .name {
        font-size: 75px;
    }

    .logos-mobile {
        visibility: visible;
        padding-right: 15px;
        padding-top: 1rem;
        height: 25vh;
    }
}