.footer-container {
    margin-top: 5%;
    background: linear-gradient(#eff7f6, #b7efc5);
    width: 100%;
}

.footer-text {
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
}

.footer-table {
    display: table;
    margin: 0 auto;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;
}

.footer-table-mobile {
    display: none;
}

.footer-table td, .footer-table-mobile td {
    text-align: center;
    width: 20%;
    margin: 0;
    padding-bottom: 20px;
}

.LinkedIn, .GitHub {
    text-decoration: none;
    color: black;
}

a:link, a:visited, a:active {
    text-decoration: none;
    color: black;
}

.footer-icon {
    padding-left: 10px;
    font-size: 25px;
}

@media only screen and (max-width: 1605px) and (min-width: 721px) {
    .footer-table td {
        text-align: center;
        margin: 0;
    }

    .footer-table-mobile, .footer-table {
        padding-left: 0px;
    }

    .text a {
        text-align: center;
        display: table;
        margin: 0 auto;
        padding-right: 80px;
    }

    .footer-text {
        font-size: 20px;
        margin: 0 auto;
        text-align: center;
    }
}

@media only screen and (max-width: 700px) {
    .footer-text, .footer-text-left {
        font-size: 14px;
        margin: 0 auto;
        text-align: center;
    }
    
    .footer-icon {
        padding-left: 20px;
        font-size: 13px;
    }

    .footer-table-mobile {
        display: table;
        padding-right: 10px;
        padding-bottom: 0.5rem;
    }

    .footer-table {
        display: none;
    }

    .footer-table td, .footer-table-mobile td {
        padding-bottom: 0;
        padding-top: 0;
    }

    .footer-table td *, .footer-table-mobile td *{
        padding: 0px;
    }

    .footer-table-mobile td{
        text-align: left;
    }

    .text-left {
        text-align: left;
    }

    .footer-table-mobile {
        padding-left: 45px;
    }
}

@media only screen and (max-width: 368px) {
    .footer-text {
        font-size: 13px;
    }

    .footer-table-mobile td{
        text-align: left;
    }

    .text-left {
        text-align: left;
    }

    .footer-table-mobile {
        padding-left: 45px;
    }
}
